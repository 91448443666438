<template>
    <div class="mt-4 col-md-6 offset-md-3 col-xs-12">
        <b-card :header="$t('register')">
            <b-card-body>
                <p class="text-xs-center">
                    <router-link :to="{ name: 'Login' }">
                        {{ $t('haveAccount') }}
                    </router-link>
                </p>
                <b-form @submit.prevent="onSubmit">
                    <b-form-group id="input-group-1" label-for="input-1">
                        <b-form-input
                            id="input-1"
                            type="text"
                            v-model.trim="firstName"
                            @blur="$v.firstName.$touch()"
                            :placeholder="$t('fields.firstName')"
                        />
                        <b-form-invalid-feedback :state="$v.firstName.$dirty ? !$v.firstName.$anyError : null">
                            {{ $t('errors.firstName') }}
                        </b-form-invalid-feedback>
                    </b-form-group>
                    <b-form-group id="input-group-2" label-for="input-2">
                        <b-form-input
                            id="input-2"
                            type="text"
                            v-model.trim="lastName"
                            @blur="$v.lastName.$touch()"
                            :placeholder="$t('fields.lastName')"
                        />
                        <b-form-invalid-feedback :state="$v.lastName.$dirty ? !$v.lastName.$anyError : null">
                            {{ $t('errors.lastName') }}
                        </b-form-invalid-feedback>
                    </b-form-group>
                    <b-form-group id="input-group-3" label-for="input-3">
                        <b-form-input
                            id="input-3"
                            type="email"
                            v-model.trim="email"
                            @blur="$v.email.$touch()"
                            :placeholder="$t('fields.email')"
                        />
                        <b-form-invalid-feedback :state="$v.email.$dirty ? !$v.email.$anyError : null">
                            {{ $t('errors.email') }}
                        </b-form-invalid-feedback>
                    </b-form-group>
                    <b-form-group id="password-group" label-for="input-4">
                        <b-form-input
                            id="input-4"
                            type="password"
                            v-model.trim="password"
                            @blur="$v.password.$touch()"
                            :placeholder="$t('fields.password')"
                        />
                        <b-form-invalid-feedback :state="$v.password.$dirty ? !$v.password.$anyError : null">
                            {{ $t('errors.password') }}
                        </b-form-invalid-feedback>
                    </b-form-group>
                    <b-button type="submit" class="btn btn-info pull-xs-right" :disabled="$v.$invalid">
                        {{ $t('register') }}
                    </b-button>
                </b-form>
                <hr />
                <google-login-button />
            </b-card-body>
        </b-card>
    </div>
</template>

<script>
import GoogleLoginButton from './GoogleLoginButton.vue';
import { required, email } from 'vuelidate/lib/validators';

export default {
    components: { GoogleLoginButton },
    name: 'RegisterCard',
    data() {
        return {
            firstName: '',
            lastName: '',
            email: '',
            password: ''
        };
    },
    validations: {
        firstName: {
            required
        },
        lastName: {
            required
        },
        email: {
            required,
            email
        },
        password: {
            required
        }
    },
    methods: {
        onSubmit(event) {
            event.preventDefault();
            this.$store
                .dispatch('register', {
                    firstName: this.firstName,
                    lastName: this.lastName,
                    email: this.email,
                    password: this.password
                })
                .then(() => {
                    this.$router.push({ name: 'Home' });
                });
        }
    }
};
</script>

<style></style>
