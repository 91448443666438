<template>
    <div class="container">
        <div class="container page">
            <div class="row">
                <login-form />
            </div>
        </div>
    </div>
</template>

<script>
import LoginForm from '../components/LoginForm.vue';
export default {
    components: { LoginForm },
    name: 'Login'
};
</script>

<style></style>
