<template>
    <div>
        <GoogleLogin
            :params="params"
            :renderParams="renderParams"
            :onSuccess="onSuccess"
            :onFailure="onFailure"
        ></GoogleLogin>
    </div>
</template>

<script>
import GoogleLogin from 'vue-google-login';
import { GOOGLE_CLIENT_ID } from '../common/config';

export default {
    name: 'GoogleLoginButton',
    components: { GoogleLogin },
    data() {
        return {
            params: {
                client_id: GOOGLE_CLIENT_ID
            },
            // only needed if you want to render the button with the google ui
            renderParams: {
                width: 250,
                height: 50,
                longtitle: true
            }
        };
    },
    methods: {
        onSuccess(response) {
            this.$store
                .dispatch('googleLogin', response.getAuthResponse().id_token)
                .then(() => this.$router.push({ name: 'Home' }));
        },
        onFailure(response) {
            console.log('Google failure', response);
        }
    }
};
</script>

<style></style>
