<template>
    <div class="container">
        <div class="container page">
            <div class="row">
                <register-form />
            </div>
        </div>
    </div>
</template>

<script>
import RegisterForm from '../components/RegisterForm.vue';
export default {
    components: { RegisterForm },
    name: 'Register'
};
</script>

<style></style>
